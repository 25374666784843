import { Background } from "../Background/Background";
import back_icon from "./../../icons/back_icon.svg";
import "./../../css/content.css";
import { ContentBlock } from "./ContentBlock";
import { contentData } from "../../ContentData/Instagram";
import { Navigate, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { contentScreen } from "../../ContentData/constants";
import { LanguageState } from "../../Context/languageContext";
import { INSTA_CHANGE_TYPE_URL, INSTA_CHECK_FB_LINKAGE_URL, INSTA_CHECK_TYPE_URL, INSTA_LINK_FB_URL } from "../../ContentData/InstagramURL";
export const ContentScreen = (props) => {
  let { language } = LanguageState();
  const navigate = useNavigate();
  const location = useLocation();

  // const heading = language === "id" ? contentScreen.id : contentScreen.en;
  // const content = language === "id" ? contentData.id : contentData.en;
  const heading = contentScreen[language] ? contentScreen[language] : contentScreen["en"];
  const content = contentData[language] ? contentData[language] : contentData["en"];

  if (location.pathname === `/${INSTA_CHECK_TYPE_URL}`) {
    location.state = {
      heading: heading.heading1,
      content: content.InstaCheckType,
    };
  }
  if (location.pathname === `/${INSTA_CHANGE_TYPE_URL}`) {
    location.state = {
      heading: heading.heading2,
      content: content.InstaChangeType,
    };
  }
  if (location.pathname === `/${INSTA_CHECK_FB_LINKAGE_URL}`) {
    location.state = {
      heading: heading.heading3,
      content: content.InstaCheckFBLinkage,
    };
  }
  if (location.pathname === `/${INSTA_LINK_FB_URL}`) {
    location.state = {
      heading: heading.heading4,
      content: content.InstaLinkFB,
    };
  }
  console.log(location.state.heading);
  return (
    <Background>
      <div className="content-container-main">
        <div className="back-icon-container">
          <img src={back_icon} alt="back_icon" onClick={() => navigate(`/?lang=${language}`)} />
        </div>
        <div className="content-main">
          <p className="content-heading">{location.state.heading}</p>
          <ContentBlock content={location.state.content} />
        </div>
      </div>
    </Background>
  );
};
