export const headerComponent = {
  en: {
    header: "Instagram Login Help Center",
  },
  id: {
    header: "Bantuan Login Instagram",
  },
  ja: {
    header: "Instagramログインヘルプセンター",
  },
  cn: {
    header: "Instagram登录帮助中心",
  },
  zh: {
    header: "Instagram登录帮助中心",
  },
  fr: {
    header: " Centre d'aide de connexion Instagram",
  },
  "zh-tw": {
    header: "Instagram 登入說明中心",
  },
  es: {
    header: "Centro de ayuda para iniciar sesión en Instagram",
  },
  pt: {
    header: "Central de Ajuda de Login do Instagram",
  },
};

export const contentBlock = {
  en: {
    button: "Watch Video",
  },
  id: {
    button: "Lihat video",
  },
  ja: {
    button: "動画を視聴",
  },
  cn: {
    button: "观看视频",
  },
  zh: {
    button: "观看视频",
  },
  fr: {
    button: "Regarder la vidéo",
  },
  "zh-tw": {
    button: "看影片",
  },
  es: {
    button: "Ver video",
  },
  pt: {
    button: "Assista vídeo",
  },
};

export const watchVideoHeader = {
  en: {
    header: "Watch video",
  },
  id: {
    header: "Menonton video",
  },
  ja: {
    header: "動画を視聴",
  },
  cn: {
    header: "观看视频",
  },
  zh: {
    header: "观看视频",
  },
  fr: {
    header: "Regarder la vidéo",
  },
  "zh-tw": {
    header: "看影片",
  },
  es: {
    header: "Ver video",
  },
  pt: {
    header: "Assista vídeo",
  },
};
export const contentScreen = {
  en: {
    heading1: "How to check type of Instagram account?",
    heading2: "How to change type of Instagram account?",
    heading3: "How to check if Instagram account is linked to a Facebook Page?",
    heading4: "How to link Instagram account to a Facebook Page?",
  },
  id: {
    heading1: "Ingin tahu jenis akun Instagram?",
    heading2: "Ingin mengubah jenis akun Instagram?",
    heading3: "Cara memeriksa apakah akun Instagram tertaut ke Halaman Facebook?",
    heading4: "Cara menautkan akun Instagram ke Halaman Facebook?",
  },
  ja: {
    heading1: "Instagramアカウントのタイプを確認するにはどうしますか？",
    heading2: "Instagramアカウントのタイプを変更するにはどうしますか？",
    heading3: "InstagramアカウントとFacebookページがリンクされているのを確認するにはどうしますか？",
    heading4: "InstagramアカウントをFacebookページとリンクさせるにはどうしますか？",
  },
  cn: {
    heading1: "如何查看Instagram帐户类型？",
    heading2: "如何更改Instagram帐户类型？",
    heading3: "如何查看Instagram帐户是否已关联至Facebook主页？",
    heading4: "如何将Instagram帐户关联至Facebook主页？",
  },
  zh: {
    heading1: "如何查看Instagram帐户类型？",
    heading2: "如何更改Instagram帐户类型？",
    heading3: "如何查看Instagram帐户是否已关联至Facebook主页？",
    heading4: "如何将Instagram帐户关联至Facebook主页？",
  },
  fr: {
    heading1: "Comment vérifier le type de compte Instagram ?",
    heading2: "Comment changer de type de compte Instagram ?",
    heading3: "Comment vérifier si un compte Instagram est lié à une page Facebook ?",
    heading4: "Comment lier un compte Instagram à une page Facebook ?",
  },
  "zh-tw": {
    heading1: "如何查看 Instagram 帳號的類型？",
    heading2: "如何更改 Instagram 帳號類型？",
    heading3: "如何檢查 Instagram 帳號是否連結到 Facebook 專頁？",
    heading4: "如何將 Instagram 帳號連結到 Facebook 專頁？",
  },
  es: {
    heading1: "¿Cómo revisar el tipo de cuenta de Instagram?",
    heading2: "¿Cómo cambiar el tipo de cuenta de Instagram?",
    heading3: "¿Cómo comprobar si la cuenta de Instagram está vinculada a una página de Facebook?",
    heading4: "¿Cómo vincular una cuenta de Instagram a una página de Facebook?",
  },
  pt: {
    heading1: "Como verificar o tipo de conta do Instagram?",
    heading2: "Como mudar o tipo de conta do Instagram?",
    heading3: "Como verificar se a conta do Instagram está vinculada a uma página do Facebook?",
    heading4: "Como vincular uma conta do Instagram a uma página do Facebook?",
  },
};
