import React from "react";
import arrow_icon from './../../icons/arrow_icon.svg'
import {useNavigate,useLocation} from "react-router-dom"
import { LanguageState } from "../../Context/languageContext";
export const ListComponent = (props) => {
    const {language} = LanguageState()
    const navigate = useNavigate();
      return (
       <div className = "list-view-container" onClick = {() => {
           navigate(`/${props.url}?lang=${language}`, {
                state : {   
                heading : props.heading,
                content : props.content
            }
           })
       }}>
            <p className="list-view-text">{props.superHeading}</p>
            <img 
                src = {arrow_icon} 
                alt = "arrow_icon" 
                className="list-view-arrow-icon "/> 
        </div>
    )
}