import "./../../css/content.css";
import parse from "html-react-parser";
import video_src from "./../../videos/check_account_type.m4v";
import icon_hamburger from "./../../icons/icon_hamburger.svg";
import { useNavigateCustom } from "../CustomHooks/useNavigateCustom";
import { createRef, useRef } from "react";
import { contentBlock, watchVideoHeader } from "../../ContentData/constants";
import { LanguageState } from "../../Context/languageContext";
export const ContentBlock = (props) => {
  const { language } = LanguageState();
  const videoDivs = Object.values(props.content).map(() => createRef());
  // const buttonText = language==="id" ? contentBlock.id : contentBlock.en
  let buttonText = contentBlock[language] ? contentBlock[language] : contentBlock["en"];
  const watchVideoHeaderText = watchVideoHeader[language] ? watchVideoHeader[language] : watchVideoHeader["en"];
  return (
    <>
      {Object.values(props.content).map((block, mainIndex) => {
        return (
          <div className="content-block-main">
            {block.subHeading ? <p>{block.subHeading}</p> : ""}
            {block.note ? (
              <div className="content-note-container">
                <p className="content-note-container-text">{block.note}</p>
              </div>
            ) : (
              ""
            )}
            <button
              className="watch-video-button"
              onClick={() => {
                videoDivs[mainIndex].current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
              }}
            >
              {buttonText.button}
            </button>
            <div>
              <ol>
                {Object.values(block.ol).map((li, index) => {
                  return (
                    <>
                      {typeof li === "object" ? (
                        Object.keys(li).map((item) => {
                          return (
                            <li>
                              {parse(item)}
                              <ol type="a">
                                {Object.values(li[item]).map((li_item) => {
                                  return <li>{parse(li_item)}</li>;
                                })}
                              </ol>
                            </li>
                          );
                        })
                      ) : (
                        <li>{parse(li)}</li>
                      )}
                    </>
                  );
                })}
              </ol>
            </div>
            <p style={{ width: "100%", marginTop: "0.5rem" }}>{watchVideoHeaderText.header}</p>
            <video
              className="video-container"
              ref={videoDivs[mainIndex]}
              style={{ border: "1px solid #E3E3E3", marginBottom: "1.5rem" }}
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
            >
              <source src={block.video_url} type="video/mp4" />
              {/* <source src= "https://cdn.dev.getphyllo.com/test/check_account_type.m4v" type="video/mp4"/> */}
              Video
            </video>
            {block.url_text ? (
              <div className="content-url-container" style={{ marginBottom: "2rem", marginTop: "1rem" }}>
                <a href={block.url_src} style={{ marginTop: "1rem", color: "#00A3FF" }}>
                  <b>
                    <u>{block.url_text}</u>
                  </b>
                </a>
              </div>
            ) : (
              ""
            )}
            <div></div>
          </div>
        );
      })}
    </>
  );
};
