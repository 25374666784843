import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import { ContentScreen } from "./components/Content/ContentScreen";
import {
  INSTA_CHANGE_TYPE_URL,
  INSTA_CHECK_FB_LINKAGE_URL,
  INSTA_CHECK_TYPE_URL,
  INSTA_LINK_FB_URL,
} from "./ContentData/InstagramURL";
import { LanguageState } from "./Context/languageContext";
import { HomeScreen } from "./Screens/Instagram/HomeScreen";

function App() {
  const { language, setLanguage } = LanguageState();

  useEffect(() => {
    const queryParam = window.location.search;
    const lang = new URLSearchParams(queryParam).get("lang");

    if (lang === null) setLanguage("en");
    else setLanguage(lang);
    console.log(language);
  }, []);

  return (
    <div className="App">
      <Routes path="">
        <Route element={<HomeScreen />} index />
        <Route path={`/${INSTA_CHECK_TYPE_URL}`} element={<ContentScreen />} />
        <Route path={`/${INSTA_CHANGE_TYPE_URL}`} element={<ContentScreen />} />
        <Route
          path={`/${INSTA_CHECK_FB_LINKAGE_URL}`}
          element={<ContentScreen />}
        />
        <Route path={`/${INSTA_LINK_FB_URL}`} element={<ContentScreen />} />
        <Route path="/*" element={<HomeScreen />} />
      </Routes>
    </div>
  );
}

export default App;
