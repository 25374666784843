import { contentData } from "./Instagram";
import { INSTA_CHANGE_TYPE_URL, INSTA_CHECK_FB_LINKAGE_URL, INSTA_CHECK_TYPE_URL, INSTA_LINK_FB_URL } from "./InstagramURL";

export const InstagramHomeBasedOnLang = {
  en: {
    1: {
      superHeading: "Check type of Instagram account",
      heading: "How to check type of Instagram account?",
      content: contentData.en.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "Change type of Instagram account",
      heading: "How to change type of Instagram account?",
      content: contentData.en.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "Check if Instagram account is linked to a Facebook Page",
      heading: "How to check if Instagram account is linked to a Facebook Page?",
      content: contentData.en.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "Link Instagram account to a Facebook Page",
      heading: "How to link Instagram account to a Facebook Page?",
      content: contentData.en.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  id: {
    1: {
      superHeading: "Periksa jenis akun Instagram",
      heading: "Ingin tahu jenis akun Instagram?",
      content: contentData.id.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "Ubah jenis akun Instagram",
      heading: "Ingin mengubah jenis akun Instagram?",
      content: contentData.id.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "Periksa jika akun Instagram tertaut ke Halaman Facebook",
      heading: "Cara memeriksa apakah akun Instagram tertaut ke Halaman Facebook?",
      content: contentData.id.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "Tautkan akun Instagram ke Halaman Facebook",
      heading: "Cara menautkan akun Instagram ke Halaman Facebook?",
      content: contentData.id.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  ja: {
    1: {
      superHeading: "Instagramアカウントのタイプを確認",
      heading: "Instagramアカウントのタイプを確認するにはどうしますか？",
      content: contentData.ja.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "Instagramアカウントのタイプを変更",
      heading: "Instagramアカウントのタイプを変更するにはどうしますか？",
      content: contentData.ja.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "InstagramアカウントをFacebookページとリンクさせているかを確認",
      heading: "InstagramアカウントとFacebookページがリンクされているのを確認するにはどうしますか？",
      content: contentData.ja.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "InstagramアカウントをFacebookページとリンクさせる",
      heading: "InstagramアカウントをFacebookページとリンクさせるにはどうしますか？",
      content: contentData.ja.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  cn: {
    1: {
      superHeading: "查看Instagram帐户类型",
      heading: "如何查看Instagram帐户类型？",
      content: contentData.cn.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "更改Instagram帐户类型",
      heading: "如何更改Instagram帐户类型？",
      content: contentData.cn.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "查看Instagram帐户是否已关联Facebook主页",
      heading: "如何查看Instagram帐户是否已关联至Facebook主页？",
      content: contentData.cn.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "将Instagram帐户关联至Facebook主页",
      heading: "如何将Instagram帐户关联至Facebook主页？",
      content: contentData.cn.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  zh: {
    1: {
      superHeading: "查看Instagram帐户类型",
      heading: "如何查看Instagram帐户类型？",
      content: contentData.zh.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "更改Instagram帐户类型",
      heading: "如何更改Instagram帐户类型？",
      content: contentData.zh.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "查看Instagram帐户是否已关联Facebook主页",
      heading: "如何查看Instagram帐户是否已关联至Facebook主页？",
      content: contentData.zh.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "将Instagram帐户关联至Facebook主页",
      heading: "如何将Instagram帐户关联至Facebook主页？",
      content: contentData.zh.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  fr: {
    1: {
      superHeading: " Vérifiez le type de compte Instagram",
      heading: " Comment vérifier le type de compte Instagram ?",
      content: contentData.fr.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: " Changer le type de compte Instagram",
      heading: "Comment changer de type de compte Instagram ?",
      content: contentData.fr.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "Vérifiez si le compte Instagram est lié à une page Facebook",
      heading: " Comment vérifier si un compte Instagram est lié à une page Facebook ?",
      content: contentData.fr.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "Associer un compte Instagram à une page Facebook",
      heading: " Comment lier un compte Instagram à une page Facebook ?",
      content: contentData.fr.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  "zh-tw": {
    1: {
      superHeading: "檢查 Instagram 帳戶的類型",
      heading: "如何查看 Instagram 帳號的類型？",
      content: contentData["zh-tw"].InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "更改 Instagram 帳戶類型",
      heading: "如何更改 Instagram 帳號類型？ ↗",
      content: contentData["zh-tw"].InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "檢查 Instagram 帳號是否連結到 Facebook 頁面",
      heading: "如何檢查 Instagram 帳號是否連結到 Facebook 專頁？",
      content: contentData["zh-tw"].InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "將 Instagram 帳號連結到 Facebook 頁面",
      heading: "如何將 Instagram 帳號連結到 Facebook 專頁？",
      content: contentData["zh-tw"].InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  es: {
    1: {
      superHeading: "Verificar tipo de cuenta de Instagram",
      heading: "¿Cómo revisar el tipo de cuenta de Instagram?",
      content: contentData.es.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: "Cambiar tipo de cuenta de Instagram",
      heading: "¿Cómo cambiar el tipo de cuenta de Instagram?",
      content: contentData.es.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: "Comprueba si tu cuenta de Instagram está vinculada a una página de Facebook",
      heading: "¿Cómo comprobar si la cuenta de Instagram está vinculada a una página de Facebook?",
      content: contentData.es.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: "Vincular una cuenta de Instagram a una página de Facebook",
      heading: "¿Cómo vincular una cuenta de Instagram a una página de Facebook?",
      content: contentData.es.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
  pt: {
    1: {
      superHeading: " Verifique o tipo de conta do Instagram",
      heading: " Como verificar o tipo de conta do Instagram?",
      content: contentData.pt.InstaCheckType,
      url: INSTA_CHECK_TYPE_URL,
    },
    2: {
      superHeading: " Alterar tipo de conta do Instagram",
      heading: " Como mudar o tipo de conta do Instagram?",
      content: contentData.pt.InstaChangeType,
      url: INSTA_CHANGE_TYPE_URL,
    },
    3: {
      superHeading: " Verifique se a conta do Instagram está vinculada a uma página do Facebook",
      heading: "Como verificar se a conta do Instagram está vinculada a uma página do Facebook?",
      content: contentData.pt.InstaCheckFBLinkage,
      url: INSTA_CHECK_FB_LINKAGE_URL,
    },
    4: {
      superHeading: " Vincule a conta do Instagram a uma página do Facebook",
      heading: "Como verificar se a conta do Instagram está vinculada a uma página do Facebook?",
      content: contentData.pt.InstaLinkFB,
      url: INSTA_LINK_FB_URL,
    },
  },
};
