import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Input, Space } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import './../../css/main.css'
import search_icon from './../../icons/search_icon.svg'

export const SearchComponent =  (props) => {
  
  return (
    <div className = "input-container">
      <input 
        className="input-search"
        placeholder={props.placeholder} 
        value = {props.searchValue}
        onChange = {(e) => {
          e.preventDefault();
          props.setSearchValue(e.target.value)
          props.handleSearch(e.target.value)
        }}
        />
      <img 
        src = {search_icon} 
        alt = "search_icon" 
        className="input-search-icon"/> 
    </div>
  )
};