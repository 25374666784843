import React from "react";
import instafb_logo from "../../icons/insta_fb_logos.svg";
import "./../../css/header.css";
import { headerComponent } from "../../ContentData/constants";
import { LanguageState } from "../../Context/languageContext";

export const HeaderComponent = () => {
  const { language } = LanguageState();
  // const headerText =
  //   language === "id" ? headerComponent.id : headerComponent.en;
  const headerText = headerComponent[language] ? headerComponent[language] : headerComponent["en"];
  return (
    <div className="app-header">
      <img src={instafb_logo} alt="icon" className="phyllo-icon" />
      <p>{headerText.header}</p>
    </div>
  );
};
