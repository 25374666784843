import React from "react";
import 'antd/dist/antd.css';
import { Card } from 'antd';
import { Background } from "../../components/Background/Background";
import {HeaderComponent} from "../../components/Header/HeaderComponent";
import { Main } from "../../components/Content/Main";

export const HomeScreen = () => {
    return (
        <Background>
                <HeaderComponent />
                <Main/>            
        </Background>
    )
}