import { INSTA_CHANGE_TYPE_URL, INSTA_LINK_FB_URL } from "./InstagramURL";

export const contentData = {
  en: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: 'Go to your profile page on Instagram and select <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > on the top right corner and select <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Scroll all the way down, and select <b>Account type and tools</b>",
          3: `If you see the <b>Switch to professional account</b> option, that means your account is of personal type. <a href = "change-type-of-instagram-account?lang=en"><b><u></u>See how to change type of Instagram account?↗</u></b></a>`,
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type_2.mp4",
        url_text: "How to change type of Instagram account? ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=en`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. Switch your Personal account to a Creator account: ",
        note: "",
        ol: {
          1: 'Go to your profile page on Instagram and select <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > on the top right corner and select <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Scroll all the way down, and select <b>Account type and tools</b>",
          3: "If you see the <b>Switch to professional account </b>option, that means your account is of personal type. ",
          4: "Select <b>Switch to a professional account</b> and click <b>Continue</b> to go through the onboarding steps.",
          5: "You can choose a category that best describes what you do from the options provided and then select Done.",
          6: "Select account type as <b>Creator</b> and then select Next.",
          7: "You have successfully converted your Instagram account to Creator type.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type_2.mp4",
        url: "",
      },
      block2: {
        subHeading: "B. Switch your Personal account to a Business account:",
        note: "",
        ol: {
          1: 'Go to your profile page on Instagram and select <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > on the top right corner and select <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Scroll all the way down, and select <b>Account type and tools</b>",
          3: "If you see the <b>Switch to professional account</b> option, that means your account is of personal type. ",
          4: "Select <b>Switch to a professional account</b> and click <b>Continue</b> to go through the onboarding steps.",
          5: "You can choose a category that best describes what you do from the options provided and  select Next .",
          6: "Review your contact info and select Next.",
          7: "To successfully connect with Instagram, you need to link your account with a Facebook Page. Follow the next steps and log in to Facebook.",
          8: "<b>Choose a Page</b> from your Pages that you'd like to connect to <b>or select Create a New Facebook Page.</b>",
          9: "Tap <b>Connect</b> after you've selected a Page or created a new Page.",
          10: "You have successfully converted your Instagram account to Business type, and linked with a Facebook Page.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new_2.mp4",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "Note: You need a Creator or Business account to link a Facebook Page. ",
        ol: {
          1: "Go to your Instagram profile on the app.",
          2: "Click on <b>Edit Profile</b>.",
          3: "Under the Profile Information / Public Business Information section, select <b>Page</b>.",
          4: {
            "Here,": {
              1: `If you are asked to Connect to a Facebook Page, it means that your <b>Instagram account is NOT linked to a Facebook page</b>. <a href = "link-instagram-account-to-facebook-page?lang=en"><b><u>See how to link an Instagram account to a Facebook page ↗ </u></b></a>`,
              2: "If you are asked to Manage Connection, it means that your <b>Instagram account is linked to a Facebook page</b>.",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "How to link an Instagram account to a Facebook page ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=en`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "A. Link account from Instagram",
        note: "",
        ol: {
          1: "Go to your Instagram profile on the app.",
          2: "Click on <b>Edit Profile</b>.",
          3: "Under the Profile Information / Public Business Information section, select <b>Connect or Create</b> under the <b>Page</b> option.",
          4: "Follow the next steps and log in to Facebook.",
          5: "<b>Choose a Page</b> from your Pages that you'd like to connect to <b>or select Create a New Facebook Page.</b>",
          6: "Tap Connect after you've selected a Page or created a new Page.",
          7: "You have successfully linked your Instagram account to a Facebook page.",
          8: "If this doesn’t work, try linking accounts from Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page_2.mp4",
        url_text: "",
        url_src: "",
      },
      block2: {
        subHeading: "B. Link account from Facebook",
        note: "",
        ol: {
          1: "Go to your profile option on Facebook and select the profile you want to link with Instagram.",
          2: "Once you've selected your desired profile, select Settings and Privacy, and then select <b>Settings</b>.",
          3: "Now select <b>Linked Accounts</b>",
          4: "Under Instagram, select <b>Connect Account</b>.",
          5: "Follow the next steps and log in to Instagram account you want to link",
          6: "Select the account type Creator or Business, then select the category of your page.",
          7: "Select Done. You have successfully connected your Facebook page to your Instagram account.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_account_from_fb.mp4",
        url_text: "",
        url_src: "",
      },
    },
  },
  id: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: 'Buka halaman profil di Instagram dan ketuk <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > di sudut kanan atas dan pilih <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">  .',
          2: {
            "Pilih Akun, lalu scroll ke bagian bawah opsi": {
              1: `Jika kamu melihat opsi Beralih ke Akun Profesional, ini berarti akun kamu adalah jenis pribadi. <a href = /${INSTA_CHANGE_TYPE_URL}?lang=id ><b><u>Tahu cara mengubah jenis akun Instagram ?↗</u></b></a>`,
              2: `Jika kamu melihat opsi Beralih Jenis Akun, klik ini. kamu dapat melihat opsi untuk memilih dua jenis akun yang tidak kamu miliki saat ini. <br><br> Misalnya, jika kamu memiliki <b>akun bisnis</b> , kamu akan memiliki opsi untuk beralih ke <b>akun pribadi</b> atau <b>akun kreator.</b>`,
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type.m4v",
        url_text: "Cara mengubah jenis akun Instagram? ↗ ",
        url_src: `/${INSTA_CHANGE_TYPE_URL}?lang=id`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. Beralih dari akun Pribadi ke akun Kreator: ",
        note: "",
        ol: {
          1: 'Buka halaman profil di Instagram dan ketuk <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > di sudut kanan atas, lalu pilih <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Pilih Akun, lalu scroll ke bagian bawah opsi.",
          3: "Pilih Beralih ke Akun Profesional dan klik Lanjutkan untuk menjalani langkah pengenalan.",
          4: "kamu bisa memilih kategori yang paling menggambarkan apa yang kamu lakukan dari opsi yang disediakan, lalu klik Selesai.",
          5: "Pilih jenis <b>akun Kreator</b> , dan klik Berikutnya.",
          6: "kamu berhasil mengonversi akun Instagram kamu menjadi jenis Kreator.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type.m4v",
        url: "",
      },
      block2: {
        subHeading: "B. Beralih dari akun Pribadi ke akun Bisnis:",
        note: "",
        ol: {
          1: 'Buka halaman profil di Instagram dan ketuk <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > di sudut kanan atas, lalu pilih <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Pilih Akun, lalu scroll ke bagian bawah opsi.",
          3: "Pilih Beralih ke Akun Profesional dan klik Lanjutkan untuk menjalani langkah pengenalan.",
          4: "kamu bisa memilih kategori yang paling menggambarkan apa yang kamu lakukan dari opsi yang disediakan, lalu klik Selesai.",
          5: "Pilih kategori untuk Bisnis dan ketuk Berikutnya.",
          6: "Tinjau info kontak kamu dan klik Berikutnya.",
          7: "Agar berhasil terhubung dengan Instagram, kamu perlu menautkan akun kamu dengan Halaman Facebook. Klik Masuk dengan Facebook di layar 'Hubungkan dengan Facebook'.",
          8: "Selanjutnya, teruskan dengan akun Facebook terkait.",
          9: "Jika akun Instagram dan Facebook sudah sesuai, klik Lanjutkan.",
          10: "Selanjutnya, klik Ya, Selesaikan Pengaturan. ",
          11: "Pilih Halaman Facebook yang relevan dari daftar yang ditampilkan, jika tidak ada klik Buat Baru dan ikuti langkah membuat halaman.",
          12: "Pilih Halaman Facebook terkait dan klik Berikutnya.",
          13: "kamu berhasil mengonversi akun Instagram kamu menjadi jenis Bisnis, dan tertaut dengan Halaman Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new.m4v",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "Catatan: kamu butuh akun Kreator atau Bisnis untuk menautkan ke Halaman Facebook. ",
        ol: {
          1: "Buka profil Instagram di aplikasi.",
          2: "Klik <b>Edit Profil.</b> ",
          3: "Di bagian Informasi Profil/Informasi Bisnis Publik, pilih <b>Halaman.</b> ",
          4: {
            "Di sini,": {
              1: `Jika kamu diminta untuk Menghubungkan ke Halaman Facebook, ini berarti akun Instagram kamu TIDAK tertaut ke Halaman Facebook. </b> <a href = /${INSTA_LINK_FB_URL}?lang=id><b><u>Lihat cara menautkan akun Instagram ke halaman Facebook ↗ </u></b></a>`,
              2: "Jika kamu diminta untuk Kelola Koneksi, ini berarti akun Instagram kamu tertaut ke Halaman Facebook.</b>.",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "Cara menautkan akun Instagram ke halaman Facebook ↗",
        url_src: `/${INSTA_LINK_FB_URL}?lang=id`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "",
        note: "Catatan: kamu butuh akun Kreator atau Bisnis untuk menautkan ke Halaman Facebook.",
        ol: {
          1: "Buka profil Instagram di aplikasi.",
          2: "Klik Edit Profil.",
          3: "Di bagian Informasi Profil/Informasi Bisnis Publik, pilih Halaman.",
          4: "Ketuk Buat Halaman Facebook atau Hubungkan ke Halaman yang Ada.",
          5: "Pilih Halaman dari Halaman kamu yang ingin dihubungkan, atau pilih Buat Halaman Facebook Baru.",
          6: "Ketik Selesai setelah kamu memilih Halaman atau membuat Halaman baru.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page.m4v",
        url_text: "",
        url_src: "",
      },
    },
  },
  ja: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: ' Instagramのプロフィールページに移動し、右上隅の<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >をタップし、<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">を選択する',
          2: {
            "アカウントを選択し、オプションの一番下までスクロールする": {
              1: `<b>プロアカウントに切り替えるオプション</b>が表示されている場合、アカウントが個人用アカウントであるということです。<a href = "change-type-of-instagram-account?lang=ja"><b><u>Instagramアカウントのタイプを変更する方法をご覧に鳴りますか？↗</u></b></a>`,
              2: "<b>アカウントのタイプを切り替える</b>オプションが表示されている場合、これをクリックしてください。現在のものではない2つのアカウントから選ぶオプションを利用できます </br>例えば、ビジネスアカウントを持っている場合、個人用アカウントかクリエーターアカウントに切り替えるオプションを利用できます",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type.m4v",
        url_text: "Instagramアカウントのタイプを変更するにはどうしますか？ ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=ja`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. 個人用アカウントをクリエーターアカウントに切り替えます:",
        note: "",
        ol: {
          1: 'Instagramのプロフィールページに移動し、右上隅の<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >をタップし、<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">を選択する',
          2: "アカウントを選択し、オプションの一番下までスクロールする",
          3: "<b>プロアカウントへの切り替え</b>を選択し、<b>続行</b>をクリックしてオンボーディング過程を完了する",
          4: "表示されるオプションから最も適したカテゴリーを選択し、完了をクリックする",
          5: "アカウントタイ<b>プでクリエータ</b>ーを選んで、次へをクリックする",
          6: "これでアカウントがクリエーターに変更されました",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type.m4v",
        url: "",
      },
      block2: {
        subHeading: "B. 個人用アカウントをビジネスアカウントに切り替えます:",
        note: "",
        ol: {
          1: 'Instagramのプロフィールページに移動し、右上隅の<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >をタップし、<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">を選択する',
          2: "アカウントを選択し、オプションの一番下までスクロールする",
          3: "<b>プロアカウントへの切り替え</b>を選択し、<b>続行</b>をクリックしてオンボーディング過程を完了する",
          4: "表示されるオプションから最も適したカテゴリーを選択し、完了をクリックする",
          5: "ビジネスのカテゴリーを選択して、次へをクリックする",
          6: "連絡先情報を確認して、次へをクリックする",
          7: `Instagramと適切に接続するには、お使いのアカウントをFacebookページとリンクする必要があります。「Facebookに接続」画面で<b>Facebookでログイン</b>をクリックしてください`,
          8: "次に、<b>対象のFacebookアカウン</b>トに移動する",
          9: "InstagramアカウントとFacebookアカウントの組み合わせに間違いがないなら、<b>続行</b>をクリックする",
          10: "次に はい をクリックしてセットアップを完了する",
          11: "表示されるリストから対象のFacebookページを選ぶか、新しいFacebookページを作成をクリックして、ページ作成の手順に従う",
          12: "対象のFacebookページを選択し、次へをクリックする",
          13: "これでInstagramアカウントがビジネスアカウントに変更され、Facebookページとリンクされています",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new.m4v",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "注意: Facebookページとリンクするにはクリエイターまたはビジネスアカウントが必要です。",
        ol: {
          1: "GアプリでInstagramのプロフィールを開く",
          2: "<b>プロフィール編集を</b>クリックする",
          3: "プロフィール情報/公開ビジネス情報のセクションで<b>ページ</b>を選択する",
          4: {
            "ここで,": {
              1: `Facebookページへの接続を聞かれた場合、この<b>InstagramアカウントはFacebookページ<b>とリンクされていないということです。<a href = "link-instagram-account-to-facebook-page?lang=ja"><b><u>InstagramアカウントをFacebookページとリンクする方法をご覧ください ↗</u></b></a>`,
              2: "接続を管理するかと聞かれた場合、この<b>InstagramアカウントはFacebookページ</b>とリンクされているということです。",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "InstagramアカウントをFacebookページとリンクさせるにはどうしますか？ ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=ja`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "",
        note: "注意: Facebookページとリンクするにはクリエイターまたはビジネスアカウントが必要です。",
        ol: {
          1: "アプリでInstagramのプロフィールを開く",
          2: "<b>プロフィール編集を</b>クリックする",
          3: "プロフィール情報/公開ビジネス情報のセクションで<b>ページ</b>を選択する",
          4: "<b>Facebookページ</b>の作成か既<b>存のページとの接続をタップする</b>",
          5: "ページのリストからリ<b>ンクしたいページを</b>選ぶか、新しい<b>Facebookページ</b>の作成を選択する",
          6: "ページを選択するか、新しいページを作成したら、完了をタップする",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page.m4v",
        url_text: "",
        url_src: "",
      },
    },
  },
  cn: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: '打开你的Instagram主页，点击右上角的<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >并选择<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: {
            "选择帐户，并滚动至选项底部": {
              1: `如果你能看到切换为<b>专业帐户选项</b>，意味着你的帐户是个人类型。<a href = "change-type-of-instagram-account?lang=cn"><b><u>查看如何更改Instagram帐户类型？↗</u></b></a>`,
              2: "如果你看到<b>切换帐户类型选项</b>，请点击。你可以看到选择你当前没有的两种帐户类型的选项。</br>例如，如果你的是业务帐户，就会看到切换为个人帐户或创作者帐户的选项。",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type.m4v",
        url_text: "如何更改Instagram帐户类型？ ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=cn`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. 将你的个人帐户切换为创作者帐户：",
        note: "",
        ol: {
          1: '打开你的Instagram主页，点击右上角的<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >并选择<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: "选择帐户，并滚动至选项底部。",
          3: "选择<b>切换为专业帐户</b>，点击<b>继续</b>并完成新手引导步骤。",
          4: "你可以从提供的选项中选择最能贴切描述你所做的事情的类别，并点击完成。",
          5: "将帐户类型选择为<b>创作者</b>，然后点击下一步。",
          6: "你已成功将你的Instagram帐户类型转化为创作者类型。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type.m4v",
        url: "",
      },
      block2: {
        subHeading: "B. 将你的个人帐户切换为业务帐户：",
        note: "",
        ol: {
          1: '打开你的Instagram主页，点击右上角的<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >并选择<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: "选择帐户，并滚动至选项底部。",
          3: "选择<b>切换为专业帐户</b>，点击<b>继续</b>并完成新手引导步骤。",
          4: "你可以从提供的选项中选择最能贴切描述你所做的事情的类别，并点击完成。",
          5: "选择你的业务类别，然后点击下一步。",
          6: "检查你的联系方式并点击下一步。",
          7: "为了成功连接Instagram，你需要将帐户关联至Facebook主页。在“关联至Facebook”界面<b>上点击用Facebook帐户登录</b>。",
          8: "接下来继续填写<b>相关的Facebook帐户信息</b>。",
          9: "如果确实希望关联Instagram帐户和Facebook帐户，则点击<b>继续</b>。",
          10: "接下来，点击“是，完成设置”。",
          11: "选择列出的相关Facebook主页，或者点击新建Facebook主页，并按照步骤创建一个。",
          12: "选择相关Facebook主页并点击下一步。",
          13: "你已成功将你的Instagram帐户类型转化为业务类型，并已关联至Facebook主页。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new.m4v",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "注意：你需要有创作者或业务帐户才能关联至Facebook主页。 ",
        ol: {
          1: "在应用中打开你的Instagram主页。",
          2: "点击<b>编辑主页</b>。",
          3: "在主页信息/公开业务信息板块，选择“<b>页</b>面”。",
          4: {
            "在此处，": {
              1: `如果收到连接至Facebook主页的提示，<b>表明你的Instagram帐户尚未关联至Facebook主页</b>。<a href = "link-instagram-account-to-facebook-page?lang=cn"><b><u>查看如何将Instagram帐户关联至Facebook主页 ↗</u></b></a>`,
              2: "如果收到管理连接的提示，表明你的<b>Instagram帐户已关联至Facebook主页。</b>",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "如何将Instagram帐户关联至Facebook主页？ ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=cn`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "",
        note: "注意：你需要有创作者或业务帐户才能关联至Facebook主页。 ",
        ol: {
          1: "在应用中打开你的Instagram主页。",
          2: "点击<b>编辑主页</b>。",
          3: "在主页信息/公开业务信息板块，选择“<b>页</b>面”。",
          4: "点击<b>创建Facebook主页或连接已有主页</b>。",
          5: "从主页列表中<b>选择一个要连</b>接的主页，或者<b>选择新建Facebook主页</b>。",
          6: "选择或新建主页后点击完成。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page.m4v",
        url_text: "",
        url_src: "",
      },
    },
  },
  zh: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: '打开你的Instagram主页，点击右上角的<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >并选择<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: {
            "选择帐户，并滚动至选项底部": {
              1: `如果你能看到切换为<b>专业帐户选项</b>，意味着你的帐户是个人类型。<a href = "change-type-of-instagram-account?lang=zh"><b><u>查看如何更改Instagram帐户类型？↗</u></b></a>`,
              2: "如果你看到<b>切换帐户类型选项</b>，请点击。你可以看到选择你当前没有的两种帐户类型的选项。</br>例如，如果你的是业务帐户，就会看到切换为个人帐户或创作者帐户的选项。",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type.m4v",
        url_text: "如何更改Instagram帐户类型？ ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=zh`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. 将你的个人帐户切换为创作者帐户：",
        note: "",
        ol: {
          1: '打开你的Instagram主页，点击右上角的<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >并选择<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: "选择帐户，并滚动至选项底部。",
          3: "选择<b>切换为专业帐户</b>，点击<b>继续</b>并完成新手引导步骤。",
          4: "你可以从提供的选项中选择最能贴切描述你所做的事情的类别，并点击完成。",
          5: "将帐户类型选择为<b>创作者</b>，然后点击下一步。",
          6: "你已成功将你的Instagram帐户类型转化为创作者类型。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type.m4v",
        url: "",
      },
      block2: {
        subHeading: "B. 将你的个人帐户切换为业务帐户：",
        note: "",
        ol: {
          1: '打开你的Instagram主页，点击右上角的<img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >并选择<img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: "选择帐户，并滚动至选项底部。",
          3: "选择<b>切换为专业帐户</b>，点击<b>继续</b>并完成新手引导步骤。",
          4: "你可以从提供的选项中选择最能贴切描述你所做的事情的类别，并点击完成。",
          5: "选择你的业务类别，然后点击下一步。",
          6: "检查你的联系方式并点击下一步。",
          7: "为了成功连接Instagram，你需要将帐户关联至Facebook主页。在“关联至Facebook”界面<b>上点击用Facebook帐户登录</b>。",
          8: "接下来继续填写<b>相关的Facebook帐户信息</b>。",
          9: "如果确实希望关联Instagram帐户和Facebook帐户，则点击<b>继续</b>。",
          10: "接下来，点击“是，完成设置”。",
          11: "选择列出的相关Facebook主页，或者点击新建Facebook主页，并按照步骤创建一个。",
          12: "选择相关Facebook主页并点击下一步。",
          13: "你已成功将你的Instagram帐户类型转化为业务类型，并已关联至Facebook主页。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new.m4v",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "注意：你需要有创作者或业务帐户才能关联至Facebook主页。 ",
        ol: {
          1: "在应用中打开你的Instagram主页。",
          2: "点击<b>编辑主页</b>。",
          3: "在主页信息/公开业务信息板块，选择“<b>页</b>面”。",
          4: {
            "在此处，": {
              1: `如果收到连接至Facebook主页的提示，<b>表明你的Instagram帐户尚未关联至Facebook主页</b>。<a href = "link-instagram-account-to-facebook-page?lang=zh"><b><u>查看如何将Instagram帐户关联至Facebook主页 ↗</u></b></a>`,
              2: "如果收到管理连接的提示，表明你的<b>Instagram帐户已关联至Facebook主页。</b>",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "如何将Instagram帐户关联至Facebook主页？ ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=zh`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "",
        note: "注意：你需要有创作者或业务帐户才能关联至Facebook主页。 ",
        ol: {
          1: "在应用中打开你的Instagram主页。",
          2: "点击<b>编辑主页</b>。",
          3: "在主页信息/公开业务信息板块，选择“<b>页</b>面”。",
          4: "点击<b>创建Facebook主页或连接已有主页</b>。",
          5: "从主页列表中<b>选择一个要连</b>接的主页，或者<b>选择新建Facebook主页</b>。",
          6: "选择或新建主页后点击完成。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page.m4v",
        url_text: "",
        url_src: "",
      },
    },
  },
  fr: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: 'Accédez à votre page de profil sur Instagram et appuyez sur <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > dans le coin supérieur droit et sélectionnez <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: {
            "Sélectionnez Compte et faites défiler vers le bas des options": {
              1: `Si vous voyez l’option Passer au <b>compte professionnel</b>, cela signifie que votre compte est de type personnel. <a href = "change-type-of-instagram-account?lang=fr"><b><u></u>Découvrez comment changer le type de compte Instagram ?↗</u></b></a>`,
              2: "Si vous voyez l’option <b>Changer de type de compte</b>, cliquez dessus. Vous pouvez voir l'option permettant de choisir les deux types de comptes que vous n'avez pas actuellement. </br>Par exemple, si vous disposez d'un compte professionnel, vous aurez la possibilité de passer à un compte personnel ou à un compte créateur.",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type.m4v",
        url_text: "Comment changer de type de compte Instagram ? ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=fr`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: " A. Basculez votre compte personnel vers un compte Créateur :",
        note: "",
        ol: {
          1: 'Accédez à votre page de profil sur Instagram et appuyez sur <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > dans le coin supérieur droit et sélectionnez <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Sélectionnez Compte et faites défiler vers le bas des options.",
          3: "Choisissez <b>Passer à un compte professionnel</b> et cliquez sur <b>Continuer</b> pour suivre les étapes d'intégration.",
          4: "Vous pouvez choisir une catégorie qui décrit le mieux ce que vous faites parmi les options proposées, puis cliquer sur Terminé.",
          5: "Sélectionnez le type de compte comme <b>Créateur</b>, puis cliquez sur Suivant.",
          6: "Vous avez réussi à convertir votre compte Instagram en type Créateur.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type.m4v",
        url: "",
      },
      block2: {
        subHeading: "B. Basculez votre compte personnel vers un compte professionnel :",
        note: "",
        ol: {
          1: 'Accédez à votre page de profil sur Instagram et appuyez sur <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > dans le coin supérieur droit et sélectionnez <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Sélectionnez Compte et faites défiler vers le bas des options.",
          3: "Choisissez <b>Passer à un compte professionnel</b> et cliquez sur <b>Continuer</b> pour suivre les étapes d'intégration.",
          4: "Vous pouvez choisir une catégorie qui décrit le mieux ce que vous faites parmi les options proposées, puis cliquer sur Terminé.",
          5: "Sélectionnez une catégorie pour votre entreprise et appuyez sur Suivant.",
          6: "Vérifiez vos coordonnées et cliquez sur Suivant.",
          7: "Pour vous connecter avec succès à Instagram, vous devez associer votre compte à une page Facebook. Cliquez sur Se <b>connecter avec Facebook</b> sur l'écran ‘Se connecter à Facebook’.",
          8: "Ensuite, continuez avec le <b>compte Facebook concerné</b>.",
          9: "Si la combinaison du compte Instagram et du compte Facebook vous convient, cliquez sur <b>Continuer</b>.",
          10: "Ensuite, cliquez sur Oui, terminer la configuration.",
          11: "Choisissez la page Facebook appropriée parmi la liste affichée, cliquez sur Créer une nouvelle page Facebook et suivez les étapes pour créer une page.",
          12: "Sélectionnez la page Facebook appropriée et cliquez sur Suivant.",
          13: "Vous avez réussi à convertir votre compte Instagram en type Business et à le relier à une page Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new.m4v",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "Remarque : Vous avez besoin d'un compte Créateur ou Professionnel pour associer une page Facebook.",
        ol: {
          1: "Accédez à votre profil Instagram sur l'application.",
          2: "Cliquez sur <b>Modifier le profil</b>.",
          3: "Sous la section Informations de profil / Informations publiques sur l'entreprise, sélectionnez <b>Page</b>.",
          4: {
            "Ici,": {
              1: `Si vous êtes invité à vous connecter à une page Facebook, cela signifie que votre <b>compte Instagram n'est PAS lié à une page Facebook</b>. <a href = "link-instagram-account-to-facebook-page?lang=fr"><b><u>Découvrez comment lier un compte Instagram à une page Facebook ↗ </u></b></a>`,
              2: "Si vous êtes invité à gérer la connexion, cela signifie que votre <b>compte Instagram est lié à une page Facebook</b>.",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: " Comment lier un compte Instagram à une page Facebook ? ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=fr`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "",
        note: "Remarque : Vous avez besoin d'un compte Créateur ou Professionnel pour associer une page Facebook.",
        ol: {
          1: "Accédez à votre profil Instagram sur l'application.",
          2: "Cliquez sur <b>Modifier le profil</b>.",
          3: "Sous la section Informations de profil / Informations publiques sur l'entreprise, sélectionnez <b>Page</b>.",
          4: "Appuyez sur <b>Créer une page Facebook</b> ou <b>Connecter une page existante</b>.",
          5: "<b>Choisissez une page</b> parmi vos pages à laquelle vous souhaitez vous connecter ou <b>sélectionnez Créer une nouvelle page Facebook</b>.",
          6: "Appuyez sur Terminé après avoir sélectionné une page ou créé une nouvelle page.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page.m4v",
        url_text: "",
        url_src: "",
      },
    },
  },
  "zh-tw": {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: '前往您在 Instagram 上的個人資料頁面，點擊右上角的 <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >，然後選擇 <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: {
            "選擇帳戶，然後捲動到選項": {
              1: `底部如果您看到切換到<b>專業帳戶選項</b>，則表示您的帳戶是個人類型。<a href = "change-type-of-instagram-account?lang=zh-tw"><b><u></u>了解如何變更 Instagram 帳號類型？↗</u></b></a>`,
              2: "如果您看到<b>切換帳戶類</b>型選項，請按一下它。您可以看到用於選擇您目前沒有的兩種帳戶類型的選項。</br>例如，如果您有企業帳戶，您可以選擇切換到個人帳戶或建立者帳戶。",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type.m4v",
        url_text: "如何查看 Instagram 帳號的類型？ ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=zh-tw`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. 將您的個人帳號切換到建立者帳號：",
        note: "",
        ol: {
          1: '前往您在 Instagram 上的個人資料頁面，點擊右上角的 <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >，然後選擇 <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: "選擇帳戶，然後捲動到選項底部。",
          3: "選擇<b>切換到專業帳戶並</b>點擊<b>繼續</b>以完成入職步驟。",
          4: "您可以從提供的選項中選擇最能描述您所做操作的類別，然後按一下「完成」。",
          5: "選擇帳戶類型<b>Creator</b>，然後按一下“下一步。",
          6: "您已成功將 Instagram 帳號轉換為 Creator 類型。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type.m4v",
        url: "",
      },
      block2: {
        subHeading: "B. 將您的個人帳號切換為企業帳號：",
        note: "",
        ol: {
          1: '前往您在 Instagram 上的個人資料頁面，點擊右上角的 <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" >，然後選擇 <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">。',
          2: "選擇帳戶，然後捲動到選項底部。",
          3: "選擇<b>切換到專業帳戶</b>，然後按一下<b>繼續</b>以完成入職步驟。",
          4: "您可以從提供的選項中選擇最能描述您所做操作的類別，然後按一下「完成」。",
          5: "選擇您的業務類別，然後點選下一步。",
          6: "檢查您的聯絡資訊，然後按一下下一步。",
          7: "若要成功連結 Instagram，您需要將您的帳號與 Facebook 專頁關聯。<b>點擊「連接到 Facebook」畫面上的「使用 Facebook</b> 登入」",
          8: "接下來，繼續使用<b>相關的 Facebook 帳號</b>。",
          9: "如果需要 Instagram 帳號和 Facebook 帳號組合，請按一下<b>繼續</b>。",
          10: "接下來，按一下「是，完成設定」。",
          11: "在其他顯示的清單中選擇相關的 Facebook 頁面，點擊「建立新的 Facebook 頁面」並依照步驟建立頁面。 ",
          12: "選擇相關的 Facebook 專頁並點選下一步。",
          13: "您已成功將 Instagram 帳號轉換為企業類型，並與 Facebook 專頁連結。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new.m4v",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "注意：您需要建立者或企業帳戶才能連結 Facebook 頁面。",
        ol: {
          1: "在應用程式上前往您的 Instagram 個人資料。",
          2: "點選<b>編輯個人資料</b>。",
          3: "在「個人資料資訊/公共業務資訊」部分下，選擇<b>「頁面」</b>。",
          4: {
            "這裡,": {
              1: `如果系統要求您連接到 Facebook 頁面，則表示您的 <b>Instagram 帳戶未連結到 Facebook 頁面</b>。<a href = "link-instagram-account-to-facebook-page?lang=zh-tw"><b><u>了解如何將 Instagram 帳號連結到 Facebook 專頁 ↗</u></b></a>`,
              2: "如果系統要求您管理連接，則表示您的 <b>Instagram 帳戶已連結到 Facebook 頁面</b>。",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "如何將 Instagram 帳號連結到 Facebook 專頁？ ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=zh-tw`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "",
        note: "注意：您需要建立者或企業帳戶才能連結 Facebook 頁面。",
        ol: {
          1: "在應用程式上前往您的 Instagram 個人資料。",
          2: "點選<b>編輯個人資料</b>。",
          3: "在「個人資料資訊/公共業務資訊」部分下，選擇<b>頁</b>。",
          4: "點<b>選建立 Facebook 專頁</b>或<b>連結現有頁面</b>。",
          5: "<b>從</b>您想要連<結的主頁中選擇一個主頁，或選擇<b>建立新的 Facebook 主頁</b>。",
          6: "選擇頁面或建立新頁面後，點選完成。",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page.m4v",
        url_text: "",
        url_src: "",
      },
    },
  },
  es: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: 'Ve a tu página de perfil en Instagram y selecciona <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > en la esquina superior derecha y selecciona <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">',
          2: "Desplázate hacia abajo y selecciona <b>Tipo de cuenta y herramientas</b>.",
          3: `Si ves la opción <b>Cambiar a cuenta profesional</b>, significa que tu cuenta es de tipo personal. <a href = "change-type-of-instagram-account?lang=es"><b><u></u>¿Ves cómo cambiar el tipo de cuenta de Instagram?↗</u></b></a>`,
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type_2.mp4",
        url_text: "¿Cómo cambiar el tipo de cuenta de Instagram? ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=es`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. Cambia su cuenta personal a una cuenta de creador:",
        note: "",
        ol: {
          1: 'Ve a tu página de perfil en Instagram. haz clic en el <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > en la esquina superior derecha y selecciona <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Desplázate hacia abajo y selecciona <b>Tipo de cuenta y herramientas</b>.",
          3: "Si ves la opción <b>Cambiar a cuenta profesional</b>, significa que tu cuenta es de tipo personal.",
          4: "Selecciona <b>Cambiar a una cuenta profesional</b> y haz clic en <b>Continuar</b> para seguir los pasos del proceso.",
          5: "Puedes elegir una categoría que describa mejor lo que haces entre las opciones proporcionadas.",
          6: "Selecciona el tipo de cuenta como <b>Creador</b> y haz clic en continuar.",
          7: "YHas convertido con éxito tu cuenta de Instagram a una cuenta de Creador.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type_2.mp4",
        url: "",
      },
      block2: {
        subHeading: "B. Cambie su cuenta personal a una cuenta comercial:",
        note: "",
        ol: {
          1: 'Ve a la página de perfil en Instagram y haz clic en <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > en la esquina superior derecha. Selecciona <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Desplázate hacia abajo y haz clic en <b>Tipo de cuenta y herramientas</b>.",
          3: "Si ves la opción <b>Cambiar a cuenta profesional</b>, significa que tu cuenta es de tipo personal.",
          4: "Selecciona <b>Cambiar a una cuenta profesional</b> y haz clic en <b>Continuar</b> para seguir los pasos del proceso",
          5: "Puedes elegir la categoría que mejor describa lo que haces entre las opciones proporcionadas.",
          6: "Revisa tu información de contacto",
          7: "Para conectarse exitosamente con Instagram, debes vincular tu cuenta con una página de Facebook. Sigue los siguientes pasos e inicia sesión en Facebook.",
          8: "<b>Elige una de las páginas</b> disponibles para la conexión o haz clic en <b>Crear una nueva página de Facebook.</b>",
          9: "Haz clic en <b>Conectar</b> después de haber seleccionado una página o creado una nueva página.",
          10: "Convertiste exitosamente tu cuenta de Instagram al tipo Business y la vinculaste con una página de Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new_2.mp4",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: "Nota: Necesitas una cuenta de Creador o Empresa para vincular una página de Facebook.",
        ol: {
          1: "Ve a tu perfil de Instagram en la aplicación.",
          2: "Haz clic en <b>Editar perfil.</b>",
          3: "En la sección Información de perfil/Información comercial pública, selecciona <b>Página</b>.",
          4: {
            "Aquí,": {
              1: `Si te pide que conectes una página de Facebook, significa que tu cuenta de <b>Instagram NO está vinculada a una página de Facebook</b>. <a href = "link-instagram-account-to-facebook-page?lang=es"><b><u></u>Ve cómo vincular una cuenta de Instagram a una página de Facebook ↗</u></b></a>`,
              2: "Si te pide que administres la conexión, significa que tu cuenta de <b>Instagram está vinculada a una página de Facebook</b>.",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: "¿Cómo vincular una cuenta de Instagram a una página de Facebook ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=es`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "A. Vincular cuenta de Instagram",
        note: "",
        ol: {
          1: "Ve a tu perfil de Instagram en la aplicación.",
          2: "Haz clic en <b>Editar perfil</b>.",
          3: "En la sección Información de perfil/Información comercial pública, selecciona <b>Conectar o Crear</b> en la opción <b>Página</b>.",
          4: "Sigue los siguientes pasos e inicia sesión en Facebook.",
          5: "<b>Elige una de las página</b> que te gustaría conectarse o <b>selecciona Crear una nueva página de Facebook.</b>",
          6: "Haz clic en Conectar después de haber seleccionado una página o creado una nueva página.",
          7: "Has vinculado con éxito su cuenta de Instagram a una página de Facebook.",
          8: "Si esto no funciona, intenta vincular cuentas de Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page_2.mp4",
        url_text: "",
        url_src: "",
      },
      block2: {
        subHeading: "B. Vincular cuenta de Facebook",
        note: "",
        ol: {
          1: "Ve a la opción de su perfil en Facebook y selecciona el perfil que deseas vincular con Instagram.",
          2: "Una vez que hayas seleccionado el perfil deseado, ve a Configuración y Privacidad, y luego selecciona <b>Configuración</b>.",
          3: "Ahora selecciona <b>Cuentas vinculadas</b>.",
          4: "En Instagram, selecciona <b>Conectar cuenta</b>.",
          5: "Sigue los siguientes pasos e inicia sesión en la cuenta de Instagram que deseas vincular.",
          6: "Selecciona el tipo de cuenta Creador o Business, luego selecciona la categoría de tu página.",
          7: "Has conectado con éxito tu página de Facebook a su cuenta de Instagram.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_account_from_fb.mp4",
        url_text: "",
        url_src: "",
      },
    },
  },
  pt: {
    InstaCheckType: {
      block1: {
        subHeading: "",
        note: "",
        ol: {
          1: 'Vá para a página do seu perfil no Instagram e selecione <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > no canto superior direito e selecione <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Role até o fim e selecione <b>Tipo de conta e ferramentas</b>",
          3: `Se você vir a opção <b>Mudar para conta profissional</b>, significa que sua conta é do tipo pessoal. <a href = "change-type-of-instagram-account?lang=pt"><b><u></u>Veja como alterar o tipo de conta do Instagram?↗</u></b></a>`,
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_account_type_2.mp4",
        url_text: " Como mudar o tipo de conta do Instagram? ↗ ",
        url_src: `${INSTA_CHANGE_TYPE_URL}?lang=pt`,
      },
    },

    InstaChangeType: {
      block1: {
        subHeading: "A. Mude sua conta pessoal para uma conta de criador:",
        note: "",
        ol: {
          1: 'Vá para a página do seu perfil no Instagram e selecione <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > no canto superior direito e selecione <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Role até o fim e selecione <b>Tipo de conta e ferramentas</b>",
          3: "Se você vir a opção <b>Mudar para conta profissional</b>, significa que sua conta é do tipo pessoal.",
          4: "Selecione <b>Mudar para uma conta profissional</b> e clique em <b>Continuar</b> para seguir as etapas de integração.",
          5: "Você pode escolher uma categoria que melhor descreva o que você faz nas opções fornecidas e selecionar Concluído.",
          6: "Selecione o tipo de conta como <b>Criador</b> e selecione Próximo.",
          7: "Você converteu com sucesso sua conta do Instagram para o tipo Criador.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_creator_type_2.mp4",
        url: "",
      },
      block2: {
        subHeading: "B. Mude sua conta pessoal para uma conta empresarial:",
        note: "",
        ol: {
          1: 'Vá para a página do seu perfil no Instagram e selecione <img src =  "https://cdn.getphyllo.com/guides/icons/icon_hamburger.svg" > no canto superior direito e selecione <img src = "https://cdn.getphyllo.com/guides/icons/icon_settings.svg">.',
          2: "Role até o fim e selecione <b>Tipo de conta e ferramentas</b>",
          3: "Se você vir a opção <b>Mudar para conta profissional</B>, significa que sua conta é do tipo pessoal.",
          4: "Selecione <b>Mudar para uma conta profissional</b> e clique em <b>Continuar</b> para seguir as etapas de integração.",
          5: "Você pode escolher uma categoria que melhor descreva o que você faz nas opções fornecidas e selecionar Avançar.",
          6: "Revise suas informações de contato e selecione Próximo.",
          7: "Para se conectar com sucesso ao Instagram, você precisa vincular sua conta a uma página do Facebook. Siga os próximos passos e faça login no Facebook.",
          8: "<b>Escolha uma página</b> de suas páginas à qual você gostaria de se conectar <b>ou selecione Criar uma nova página do Facebook</b>.",
          9: "Toque em <b>Conectar</b> depois de selecionar uma página ou criar uma nova página.",
          10: "Você converteu com sucesso sua conta do Instagram para o tipo Empresarial e vinculou-a a uma página do Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/convert_to_business_new_2.mp4",
        url_text: "",
        url_src: "",
      },
    },

    InstaCheckFBLinkage: {
      block1: {
        subHeading: "",
        note: " Observação: você precisa de uma conta de Criador ou Empresa para vincular uma página do Facebook.",
        ol: {
          1: "Acesse seu perfil do Instagram no aplicativo.",
          2: "Clique em <b>Editar perfil</b>.",
          3: "Na seção Informações de perfil/Informações comerciais públicas, selecione <b>Página</b>.",
          4: {
            "Aqui,": {
              1: `Se for solicitado que você se conecte a uma página do Facebook, significa que sua conta do <b>Instagram NÃO está vinculada a uma página do Facebook</b>. <a href = "link-instagram-account-to-facebook-page?lang=pt"><b><u></u>Veja como vincular uma conta do Instagram a uma página do Facebook ↗ </u></b></a>`,
              2: "Se for solicitado que você gerencie a conexão, significa que sua conta do <b>Instagram está vinculada a uma página do Facebook</b>.",
            },
          },
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/check_page_link.m4v",
        url_text: " Como vincular uma conta do Instagram a uma página do Facebook? ↗",
        url_src: `${INSTA_LINK_FB_URL}?lang=pt`,
      },
    },

    InstaLinkFB: {
      block1: {
        subHeading: "A. Vincular conta do Instagram",
        note: "",
        ol: {
          1: "Acesse seu perfil do Instagram no aplicativo.",
          2: "Clique em <b>Editar perfil</b>.",
          3: "Na seção Informações de perfil/Informações comerciais públicas, selecione <b>Conectar ou Criar</b> na opção <b>Página</b>.",
          4: "Siga os próximos passos e faça login no Facebook.",
          5: "<b>Escolha uma página</b> de suas páginas à qual você gostaria de se <b>conectar ou selecione Criar uma nova página do Facebook</b>.",
          6: "Toque em Conectar depois de selecionar uma página ou criar uma nova página.",
          7: "Você vinculou com sucesso sua conta do Instagram a uma página do Facebook.",
          8: "Se isso não funcionar, tente vincular contas do Facebook.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_fb_page_2.mp4",
        url_text: "",
        url_src: "",
      },
      block2: {
        subHeading: "B. Vincular conta do Facebook",
        note: "",
        ol: {
          1: "Acesse a opção do seu perfil no Facebook e selecione o perfil que deseja vincular ao Instagram.",
          2: "Depois de selecionar o perfil desejado, selecione Configurações e privacidade e, em seguida, selecione <b>Configurações</b>.",
          3: "Agora selecione <b>Contas vinculadas</b>.",
          4: "Em Instagram, selecione <b>Conectar conta</b>.",
          5: "FSiga os próximos passos e faça login na conta do Instagram que deseja vincular.",
          6: "Selecione o tipo de conta Criador ou Empresa e selecione a categoria da sua página.",
          7: "Selecione Concluído. Você conectou com sucesso sua página do Facebook à sua conta do Instagram.",
        },
        video_text: "Phyllo url",
        video_url: "https://cdn.getphyllo.com/guides/videos/instagram/link_account_from_fb.mp4",
        url_text: "",
        url_src: "",
      },
    },
  },
};
