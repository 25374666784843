import { createContext, useContext, useState } from "react";

export const Language = createContext();

function LanguageContext({ children }) {
  const [language, setLanguage] = useState("en")

  return (
    <Language.Provider value={{ language, setLanguage }}>
      {children}
    </Language.Provider>
  );
}

export const LanguageState = () => useContext(Language);

export default LanguageContext;