import { List } from "antd";
import { ListComponent } from "../Common Components/ListComponent";
import { SearchComponent } from "../Common Components/SearchComponent";
import { useNavigate } from "react-router-dom";
import { InstagramHomeBasedOnLang, InstagramHomeEn } from "../../ContentData/InstagramHomeEn";
import { InstagramHomeId } from "../../ContentData/InstagramHomeId";
import { useEffect, useState } from "react";
import { mainScreen } from "../../ContentData/mainScreen";
import { LanguageState } from "../../Context/languageContext";
export const Main = () => {
  const navigate = useNavigate();
  const { language } = LanguageState();
  const InstagramHome = InstagramHomeBasedOnLang[language] ? InstagramHomeBasedOnLang[language] : InstagramHomeBasedOnLang["en"];
  const content = mainScreen[language] ? mainScreen[language] : mainScreen["en"];
  const [searchValue, setSearchValue] = useState("");
  const [listInstaHome, setListInstaHome] = useState(InstagramHome);
  const handleSearch = (str) => {
    if (str === "") setListInstaHome(InstagramHome);
    else {
      const listObj = {};
      Object.values(InstagramHome).map((item, index) => {
        if (item.superHeading.toLowerCase().includes(str.toLowerCase())) {
          listObj[index] = item;
        }
        setListInstaHome(listObj);
      });
    }
  };
  useEffect(() => {
    setListInstaHome(InstagramHome);
  }, [language]);

  return (
    <div style={{ width: "87%", height: "100vh" }}>
      <SearchComponent placeholder={content.placeholder} searchValue={searchValue} setSearchValue={setSearchValue} handleSearch={handleSearch} />
      <div style={{ marginTop: "1.5rem" }}>
        <p style={{ fontWeight: "600" }}>{content.small_heading}</p>
      </div>
      {Object.keys(listInstaHome).length > 0 ? (
        Object.values(listInstaHome).map((item) => {
          return <ListComponent key={item.index} url={item.url} superHeading={item.superHeading} heading={item.heading} content={item.content} />;
        })
      ) : (
        <>{content.no_result}</>
      )}
      <div style={{ marginTop: "2rem" }}>
        <p style={{ fontWeight: "600" }}>{content.question}</p>
      </div>
      <div>
        {content.heading}
        <ol>
          <li>{content.item1}</li>
          <li>{content.item2}</li>
        </ol>
      </div>
    </div>
  );
};
