import { contentData } from "./Instagram";
import {
  INSTA_CHANGE_TYPE_URL,
  INSTA_CHECK_FB_LINKAGE_URL,
  INSTA_CHECK_TYPE_URL,
  INSTA_LINK_FB_URL,
} from "./InstagramURL";

export const InstagramHomeId = {
  1: {
    superHeading: "Periksa jenis akun Instagram",
    heading: "Ingin tahu jenis akun Instagram?",
    content: contentData.id.InstaCheckType,
    url: INSTA_CHECK_TYPE_URL,
  },
  2: {
    superHeading: "Ubah jenis akun Instagram",
    heading: "Ingin mengubah jenis akun Instagram?",
    content: contentData.id.InstaChangeType,
    url: INSTA_CHANGE_TYPE_URL,
  },
  3: {
    superHeading: "Periksa jika akun Instagram tertaut ke Halaman Facebook",
    heading:
      "Cara memeriksa apakah akun Instagram tertaut ke Halaman Facebook?",
    content: contentData.id.InstaCheckFBLinkage,
    url: INSTA_CHECK_FB_LINKAGE_URL,
  },
  4: {
    superHeading: "Tautkan akun Instagram ke Halaman Facebook",
    heading: "Cara menautkan akun Instagram ke Halaman Facebook?",
    content: contentData.id.InstaLinkFB,
    url: INSTA_LINK_FB_URL,
  },
};
