import React from "react";
import  './../../css/background.css'
export const Background = (props) => {
    return (
        <div className="background-main">
            {/* <div className="background-card">  */}
                <div className="background-content">
                    {props.children}
                </div>
            {/* </div>  */}
        </div>
    )
}