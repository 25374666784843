export const mainScreen = {
  en: {
    small_heading: "How to",
    placeholder: "How can we help you?",
    no_result: "No matching record found.",
    question: "Why is this required for an Instagram login?",
    heading: "Instagram needs you to have the following for successfully connecting your account",
    item1: "Your Instagram account must be a Creator or Business account.",
    item2: "Your Instagram account must be connected to a Facebook Page.",
  },
  id: {
    small_heading: "Cara",
    placeholder: "Perlu bantuan?",
    no_result: "Tidak ditemukan catatan yang cocok.",
    question: "Mengapa kamu perlu untuk login Instagram?",
    heading: "Kamu perlu mengikuti langkah berikut agar berhasil menghubungkan akun kamu",
    item1: "Akun Instagram kamu harus akun Kreator atau Bisnis.",
    item2: "Akun Instagram kamu harus terhubung dengan halaman Facebook.",
  },
  ja: {
    small_heading: "やり方",
    placeholder: "どのようにお手伝いできますか？",
    no_result: "一致するレコードは見つかりませんでした",
    question: "どうしてこれがInstagramのログインに必要なのですか？",
    heading: "アカウントに接続するために、Instagramには以下が必要です",
    item1: "Instagramアカウントがクリエーターまたはビジネスアカウントである必要があります",
    item2: "InstagramアカウントがFacebookページと接続されている必要があります",
  },
  cn: {
    small_heading: "如何",
    placeholder: "请问需要什么帮助？",
    no_result: "未找到匹配记录",
    question: "为什么登录Instagram时需要提供此信息？",
    heading: "Instagram需要你满足以下条件才能成功连接帐户",
    item1: "你的Instagram帐户必须是创作者或业务帐户。",
    item2: "你的Instagram帐户必须关联至Facebook主页。",
  },
  zh: {
    small_heading: "如何",
    placeholder: "请问需要什么帮助？",
    no_result: "未找到匹配记录",
    question: "为什么登录Instagram时需要提供此信息？",
    heading: "Instagram需要你满足以下条件才能成功连接帐户",
    item1: "你的Instagram帐户必须是创作者或业务帐户。",
    item2: "你的Instagram帐户必须关联至Facebook主页。",
  },
  fr: {
    small_heading: "Comment",
    placeholder: "Comment pouvons-nous vous aider?",
    no_result: "Aucun enregistrement correspondant trouvé.",
    question: " Pourquoi est-ce requis pour une connexion Instagram ?",
    heading: "Instagram a besoin des éléments suivants pour connecter avec succès votre compte ",
    item1: "Votre compte Instagram doit être un compte Créateur ou Professionnel.",
    item2: "Votre compte Instagram doit être connecté à une page Facebook.",
  },
  "zh-tw": {
    small_heading: "如何",
    placeholder: "我們該怎樣幫助你？",
    no_result: "沒有找到符合的記錄。",
    question: "為什麼 Instagram 登入需要此資訊？",
    heading: "Instagram 需要您具備以下條件才能成功連結您的帳號 ",
    item1: "您的 Instagram 帳號必須是 Creator 或 Business 帳號。",
    item2: "您的 Instagram 帳號必須連結到 Facebook 專頁。",
  },
  es: {
    small_heading: "Cómo",
    placeholder: "¿Cómo podemos ayudarte?",
    no_result: "No se encontró ningún registro coincidente.",
    question: " ¿Por qué es necesario esto para iniciar sesión en Instagram?",
    heading: "Instagram necesita que tengas lo siguiente para conectar exitosamente tu cuenta",
    item1: "Tu cuenta de Instagram debe ser una cuenta de Creador o Comercial.",
    item2: "Tu cuenta de Instagram debe estar conectada a una página de Facebook.",
  },
  pt: {
    small_heading: "Como",
    placeholder: " Como podemos te ajudar?",
    no_result: "Nenhum registro correspondente encontrado.",
    question: "Por que isso é necessário para um login no Instagram?",
    heading: "Instagram precisa que você tenha o seguinte para conectar sua conta com sucesso",
    item1: "Sua conta do Instagram deve ser uma conta de Criador ou Empresa.",
    item2: "Sua conta do Instagram deve estar conectada a uma página do Facebook.",
  },
};
